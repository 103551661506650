import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';

export const useVueRecaptcha = async() => {
  const runtimeConfig = useRuntimeConfig().public
  const { vueApp } = useNuxtApp();
  vueApp.use(VueReCaptcha, {
    siteKey: runtimeConfig.recaptcha.siteKey,
    loaderOptions: { 
        autoHideBadge: true,
        useEnterprise: runtimeConfig.recaptcha.mode == 'enterprise'
     },
  });
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
  await recaptchaLoaded();
  return async (action) => {
    return await executeRecaptcha(action);
  };
}